import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("section", null, [_createVNode(_component_router_view, null, {
    default: _withCtx(({
      Component
    }) => [(_openBlock(), _createBlock(_KeepAlive, {
      include: "MainEntrancePage"
    }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024))]),
    _: 1
  })]);
}